const PriceAdditions = {
  PP_CUSTOM_FORM_CONSTANT: 5.5,
  CUSTOM_MEASUREMENT_TYPE_FACTOR: 1.05,
  WITH_POLY_CONSTANT: 2.8,
  FRAME_MANUFACTORING_SMALL_CONSTANT: 10.9,
  FRAME_MANUFACTORING_MEDIUM_CONSTANT: 14.2,
  FRAME_MANUFACTORING_LARGE_CONSTANT: 21.5,
  PASSEPARTOUT_ADDITIONAL_CUT_OUT: 5.5,
  FRAME_HANGERS: 3.8,
  POLY_GROUP_CONSTANTS: {
    51406: 2.58,
    52404: 2.8,
    "D8-1": 1.25,
    52001: 3.14,
    51412: 4.43,
    51005: 3.38,
    51004: 2.70,
    51016: 2.70,
  },
};
module.exports = PriceAdditions;
