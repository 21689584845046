import { createApp } from "vue";
import App from "./App.vue";

import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/storage";
import cloneDeep from "lodash/cloneDeep";

import VCalendar from "v-calendar";
import "v-calendar/style.css";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
if (localStorage.getItem("ibisOnlinebestellungCookieConsentGiven") === "true") {
  firebase.analytics();
}

import "../src/assets/sass/style.scss";

import { createStore } from "vuex";
import calculatePrice from "./utility/PriceCalculator";
import router from "./router";
import ToastTypes from "./models/Types/ToastTypes";
import ProductTypes from "./models/Types/ProductTypes";
import ProductFactory from "./utility/ProductFactory";
import ProductFamily from "./models/productFamily";
import DeviceTypes from "./models/Types/DeviceTypes";
import ConfiguratorMode from "./models/Types/ConfiguratorMode";
import WorkshopModes from "./models/Types/WorkshopModes";
import WorkshopFraming from "./models/WorkshopFraming";
import WorkshopStretching from "./models/WorkshopStretching";
import WorkshopFactory from "./utility/WorkshopFactory";
import WorkshopWork from "./models/WorkshopWork";
import WorkshopLamination from "./models/WorkshopLamination";
import Measurements from "./models/Types/Measurements";

function getValueForMeasurementType(value) {
  let finalValue;
  store.getters.currentWorkshopWork.frame.selected_measurement_type ===
    Measurements.FALZMASS
    ? (finalValue = value - store.getters.currentWorkshopWork.folding_allowance)
    : (finalValue = value);
  return finalValue;
}

// Create a new store instance.
const store = createStore({
  state() {
    return {
      productFamilies: [],
      selectedConfigurations: [],
      selectedProductFamily: undefined,
      selectedIndex: 0,
      totalPrice: 0,

      navBarVisible: false,

      showToast: false,
      currentToastType: ToastTypes.ERROR,
      currentToastMessage: "",

      currentGlasSizeOption: "",

      lastCurrentSessionOrder: undefined,

      userIsAdmin: false,

      isOrderProcessing: false,
      currentDiscountCode: undefined,

      isMobile: false,
      isTablet: false,
      isDesktop: false,

      currentConfiguratorMode: ConfiguratorMode.PRODUCT,
      currentWorkshopMode: WorkshopModes.FRAMING,
      currentWorkshopWork: new WorkshopFraming(),

      initialSync: false,
    };
  },
  mutations: {
    addProductFamily(state) {
      const newProductFamily = cloneDeep(state.selectedProductFamily);
      state.productFamilies.unshift(newProductFamily);

      this.commit("addSelectedProductFamily", cloneDeep(newProductFamily));
      state.selectedIndex = undefined;
    },
    addWorkshopWork(state) {
      const newProductFamily = cloneDeep(state.currentWorkshopWork);
      state.productFamilies.unshift(newProductFamily);
      state.currentWorkshopWork = newProductFamily;

      state.selectedIndex = undefined;
    },
    deleteProductFamily(state, index) {
      state.productFamilies.splice(index, 1);
      state.selectedConfigurations.splice(index, 1);

      if (state.selectedConfigurations.length === 0) {
        state.selectedProductFamily = undefined;
      }

      this.commit("updatePrice");
    },
    setSelectedProductFamily(state, index) {
      if (state.productFamilies[index] instanceof ProductFamily) {
        state.selectedProductFamily = state.productFamilies[index];
        state.selectedIndex = index;
        if (
          state.selectedProductFamily.products[0].productType ===
          ProductTypes.GLAS_BOARD
        ) {
          state.currentGlasSizeOption = state.selectedProductFamily.products[0];
        }
      }
    },
    addSelectedProductFamily(state, family) {
      state.selectedProductFamily = family;
      if (
        state.selectedProductFamily.products[0].productType ===
        ProductTypes.GLAS_BOARD
      ) {
        state.currentGlasSizeOption = state.selectedProductFamily.products[0];
      }
    },
    switchProduct(state, index) {
      let newProductFamily = cloneDeep(state.selectedProductFamily);
      newProductFamily.selectedIndex = index;

      state.selectedProductFamily = newProductFamily;
    },
    setSelectedIndex(state, value) {
      state.selectedIndex = value;
    },
    updateSelectedConfigurations(state) {
      for (let i = 0; i < state.productFamilies.length; i++) {
        if (state.productFamilies[i] instanceof WorkshopWork) {
          state.selectedConfigurations[i] = state.productFamilies[i];
        } else {
          state.selectedConfigurations[i] =
            state.productFamilies[i].products[
            state.productFamilies[i].selectedIndex
            ];
        }
        this.commit("updatePrice");
      }
    },
    updatePrice(state) {
      state.totalPrice = 0;
      for (const product of state.selectedConfigurations) {
        state.totalPrice += calculatePrice(product);
      }
    },
    updateNavbarVisibility(state, value) {
      state.navBarVisible = value;
    },
    configureToast(state, payload) {
      state.showToast = payload.showToast;
      state.currentToastMessage = payload.message;
      state.currentToastType = payload.toastType;
    },
    resetToast(state) {
      state.showToast = false;
    },
    setCurrentGlasSizeOption(state, option) {
      if (option) {
        state.currentGlasSizeOption = option;
      } else {
        state.currentGlasSizeOption = "";
      }
    },
    switchGlasSizeOption(state, glasSizeOption) {
      // If a product from the same family and with the same glass size option already exists, use it
      const existingOptionIndex = state.productFamilies.findIndex(
        (family) =>
          family.parent_id === state.selectedProductFamily.parent_id &&
          family.products[family.selectedIndex] === glasSizeOption
      );

      if (existingOptionIndex !== -1) {
        // If the option exists, switch to it
        state.selectedProductFamily =
          state.productFamilies[existingOptionIndex];
        state.currentGlasSizeOption = glasSizeOption;
      } else {
        // If the option doesn't exist, create a new instance
        let newProductFamily = cloneDeep(state.selectedProductFamily);

        // Change the glass size option for the selected product
        newProductFamily.products[newProductFamily.selectedIndex] =
          glasSizeOption;

        state.selectedProductFamily = newProductFamily;
        state.currentGlasSizeOption = glasSizeOption;
      }
    },

    setLastCurrentSessionOrder(state, order) {
      state.lastCurrentSessionOrder = order;
    },
    resetSelectedConfigurations(state) {
      state.selectedConfigurations = [];
      state.currentGlasSizeOption = "";
      state.productFamilies = [];
      state.selectedProductFamily = undefined;
      state.selectedIndex = 0;
      state.totalPrice = 0;
    },
    setUserAdminStatus(state, value) {
      state.userIsAdmin = value;
    },
    setUserConfiguration(state, payload) {
      state.productFamilies = payload.families;
      state.currentGlasSizeOption = payload.glasSizeOption;
      state.selectedProductFamily = payload.selectedFamily;
      state.selectedConfigurations = payload.userConfigurations;
      //state.currentWorkshopMode = payload.currentWorkshopMode;
      /* state.currentWorkshopWork = payload.currentWorkshopWork
        ? payload.currentWorkshopWork
        : state.currentWorkshopWork; */
      this.commit("initializeWorkshopWork");
      state.selectedIndex = !router.currentRoute.value.fullPath.includes(
        "restbestand"
      )
        ? payload.index
        : undefined;
      state.totalPrice = payload.price;

      this.commit("updateSelectedConfigurations");
      if (
        state.productFamilies.length > 0 &&
        !router.currentRoute.value.fullPath.includes("restbestand")
      ) {
        this.commit("setSelectedProductFamily", payload.index);
      }
      state.initialSync = true;
    },
    setOrderProcessing(state, value) {
      state.isOrderProcessing = value;
    },
    setDeviceType(state, value) {
      if (value === DeviceTypes.MOBILE) {
        state.isMobile = true;
        state.isTablet = false;
        state.isDesktop = false;
      } else if (value === DeviceTypes.TABLET) {
        state.isMobile = false;
        state.isTablet = true;
        state.isDesktop = false;
      } else {
        state.isMobile = false;
        state.isTablet = false;
        state.isDesktop = true;
      }
    },

    setConfiguratorMode(state, value) {
      state.currentConfiguratorMode = value;
    },
    setWorkshopMode(state, value) {
      state.currentWorkshopMode = value;
    },
    initializeWorkshopWork(state, reInitialize) {
      if (
        state.currentWorkshopMode !== state.currentWorkshopWork.mode ||
        reInitialize
      ) {
        if (
          state.currentWorkshopMode === WorkshopModes.FRAMING ||
          state.currentWorkshopMode === WorkshopModes.FRAME_WITH_EQUIPMENT ||
          state.currentWorkshopMode === WorkshopModes.CANVAS_FRAMING ||
          state.currentWorkshopMode === WorkshopModes.LAMINATION_FRAMING
        ) {
          state.currentWorkshopWork = new WorkshopFraming();
          state.currentWorkshopWork.mode = state.currentWorkshopMode;
          if (
            state.currentWorkshopMode === WorkshopModes.FRAME_WITH_EQUIPMENT
          ) {
            state.currentWorkshopWork.installation = undefined;
          }
          if (state.currentWorkshopMode === WorkshopModes.CANVAS_FRAMING) {
            state.currentWorkshopWork = new WorkshopFraming();
            state.currentWorkshopWork.mode = WorkshopModes.CANVAS_FRAMING;
            state.currentWorkshopWork.folding_allowance = 0.4;
          }
          else if (state.currentWorkshopMode === WorkshopModes.LAMINATION_FRAMING) {
            state.currentWorkshopWork = new WorkshopFraming();
            state.currentWorkshopWork.mode = WorkshopModes.LAMINATION_FRAMING;
            state.currentWorkshopWork.assignDefaultStretchingType();
          }

        } else if (state.currentWorkshopMode === WorkshopModes.CANVAS_STRETCH) {
          state.currentWorkshopWork = new WorkshopStretching();
        } else if (
          state.currentWorkshopMode ===
          WorkshopModes.LAMINATION_AND_SURFACE_FINISHING
        ) {
          state.currentWorkshopWork = new WorkshopLamination();
          state.currentWorkshopWork.hangings = [];
        }
      }
    },
    setWorkshopWork(state, object) {
      state.currentWorkshopWork = object;
    },
    setWorkshopFrame(state, frame) {
      state.currentWorkshopWork.frame = frame;
    },
    setWorkshopPassepartoutStatus(state, value) {
      state.currentWorkshopWork.no_passepartout = value;
    },

    setWorkshopPassepartout(state, passepartout) {
      if (state.currentWorkshopWork.frame) {
        state.currentWorkshopWork.passepartout = passepartout;
        if (
          state.currentWorkshopWork.frame.height &&
          state.currentWorkshopWork.frame.width &&
          state.currentWorkshopWork.passepartout
        ) {
          state.currentWorkshopWork.passepartout.setHeight(
            getValueForMeasurementType(state.currentWorkshopWork.frame.height),
            true
          );
          state.currentWorkshopWork.passepartout.setWidth(
            getValueForMeasurementType(state.currentWorkshopWork.frame.width),
            true
          );
        }
      }
    },
    setWorkshopSecondPassepartout(state, passepartout) {
      if (state.currentWorkshopWork.frame) {
        state.currentWorkshopWork.second_passepartout = passepartout;
        if (
          state.currentWorkshopWork.frame.height &&
          state.currentWorkshopWork.frame.width &&
          state.currentWorkshopWork.second_passepartout
        ) {
          state.currentWorkshopWork.second_passepartout.setHeight(
            getValueForMeasurementType(state.currentWorkshopWork.frame.height),
            true
          );
          state.currentWorkshopWork.second_passepartout.setWidth(
            getValueForMeasurementType(state.currentWorkshopWork.frame.width),
            true
          );
        }
      }
    },
    setDefaultGlas(state, value) {
      state.currentWorkshopWork.glas = value;
    },
    setDefaultSecondGlas(state, value) {
      state.currentWorkshopWork.second_glas = value;
    },
    switchWorkshopGlas(state, value) {
      if (state.currentWorkshopWork.frame) {
        state.currentWorkshopWork.glas = value;
        if (
          state.currentWorkshopWork.frame.height &&
          state.currentWorkshopWork.frame.width &&
          state.currentWorkshopWork.glas
        ) {
          state.currentWorkshopWork.glas.setHeight(
            getValueForMeasurementType(state.currentWorkshopWork.frame.height),
            true
          );
          state.currentWorkshopWork.glas.setWidth(
            getValueForMeasurementType(state.currentWorkshopWork.frame.width),
            true
          );
        }
      }
    },
    switchWorkshopSecondGlas(state, value) {
      if (state.currentWorkshopWork.frame) {
        state.currentWorkshopWork.second_glas = value;
        if (
          state.currentWorkshopWork.frame.height &&
          state.currentWorkshopWork.frame.width &&
          state.currentWorkshopWork.second_glas
        ) {
          state.currentWorkshopWork.second_glas.setHeight(
            getValueForMeasurementType(state.currentWorkshopWork.frame.height),
            true
          );
          state.currentWorkshopWork.second_glas.setWidth(
            getValueForMeasurementType(state.currentWorkshopWork.frame.width),
            true
          );
        }
      }
    },
    setDefaultBackWall(state, value) {
      state.currentWorkshopWork.back_wall = value;
    },
    switchWorkshopBackWall(state, value) {
      if (state.currentWorkshopWork.frame) {
        state.currentWorkshopWork.back_wall = value;
        if (
          state.currentWorkshopWork.frame.height &&
          state.currentWorkshopWork.frame.width &&
          state.currentWorkshopWork.back_wall
        ) {
          state.currentWorkshopWork.back_wall.setHeight(
            getValueForMeasurementType(state.currentWorkshopWork.frame.height),
            true
          );
          state.currentWorkshopWork.back_wall.setWidth(
            getValueForMeasurementType(state.currentWorkshopWork.frame.width),
            true
          );
        }
      }
    },
    setCurrentWorkshopWorkStretchingType(state, value) {
      state.currentWorkshopWork.stretching_type = value;
    },

    setWorkshopHangings(state, value) {
      state.currentWorkshopWork.hangings = value;
    },
    setWorkshopInstallation(state, value) {
      state.currentWorkshopWork.installation = value;
    },
    setWorkshopPictureValue(state, value) {
      state.currentWorkshopWork.picture_value = value;
    },
    setWorkshopDamage(state, value) {
      state.currentWorkshopWork.damage = value;
    },
    setWorkshopServices(state, value) {
      state.currentWorkshopWork.services = value;
    },
    switchWorkshopStretcherFrame(state, value) {
      if (state.currentWorkshopWork.frame) {
        state.currentWorkshopWork.stretcher_frame = value;
        if (
          state.currentWorkshopWork.frame.height &&
          state.currentWorkshopWork.frame.width &&
          state.currentWorkshopWork.stretcher_frame
        ) {
          state.currentWorkshopWork.stretcher_frame.setHeight(
            getValueForMeasurementType(state.currentWorkshopWork.frame.height),
            true
          );
          state.currentWorkshopWork.stretcher_frame.setWidth(
            getValueForMeasurementType(state.currentWorkshopWork.frame.width),
            true
          );
        }
      }
      else {
        state.currentWorkshopWork.stretcher_frame = value;

      }
    },
    setWorkshopStretcherClampedStatus(state, value) {
      state.currentWorkshopWork.already_clamped = value;
    },
    switchWorkshopCarrierMaterial(state, value) {
      if (state.currentWorkshopWork.frame) {
        state.currentWorkshopWork.carrier_material = value;
        if (
          state.currentWorkshopWork.frame.height &&
          state.currentWorkshopWork.frame.width &&
          state.currentWorkshopWork.carrier_material
        ) {
          state.currentWorkshopWork.carrier_material.setHeight(
            getValueForMeasurementType(state.currentWorkshopWork.frame.height),
            true
          );
          state.currentWorkshopWork.carrier_material.setWidth(
            getValueForMeasurementType(state.currentWorkshopWork.frame.width),
            true
          );
        }
      }
      else {
        state.currentWorkshopWork.carrier_material = value;
      }
    },
    setWorkshopSurfaceFinishing(state, value) {
      state.currentWorkshopWork.surface_finishing = value;
    },
    setCurrentDiscountCode(state, value) {
      state.currentDiscountCode = value;
      this.commit("updatePrice");
    },
    clearForm(state) {
      if (state.currentConfiguratorMode === ConfiguratorMode.WORKSHOP_WORKS) {
        this.commit("initializeWorkshopWork", true);
      }
      else {
        state.productFamilies = [];
        state.selectedProductFamily = undefined;
        state.selectedIndex = 0;
        state.currentGlasSizeOption = "";
      }
    }
    // ... other mutations
  },
  actions: {
    setCookieConsent(context, value) {
      if (value === true) {
        firebase.analytics().logEvent("cookie_consent_given");
        localStorage.setItem("ibisOnlinebestellungCookieConsentGiven", "true");
      } else {
        localStorage.setItem("ibisOnlinebestellungCookieConsentGiven", "false");
      }
    },
    logErrorInFirebase(context, error) {
      firebase.analytics().logEvent("exception", {
        description: error.toString(),
        fatal: false,
      });
    },
    async initializeConfigurationAsync({ commit }, payload) {
      let families = [];
      if (payload.productFamilies) {
        for (const family of payload.productFamilies) {
          if (family.parent_id) {
            let newFamily = new ProductFamily(
              family.parent_id,
              family.selectedIndex
            );
            const products = family.products;
            for (const product of products) {
              newFamily.addProduct(
                await ProductFactory.fromJson(product),
                true
              );
            }
            families.push(newFamily);
          } else {
            let newFamily = await WorkshopFactory.fromJson(family);
            families.push(newFamily);
          }
        }
      }

      let glasSizeOption = "";
      if (payload.currentGlasSizeOption) {
        glasSizeOption = await ProductFactory.fromJson(
          payload.currentGlasSizeOption
        );
      }

      let selectedFamily = undefined;

      if (payload.selectedProductFamily && !this.state.selectedProductFamily) {
        let newFamily = new ProductFamily(
          payload.selectedProductFamily.parent_id,
          payload.selectedProductFamily.selectedIndex
        );
        const products = payload.selectedProductFamily.products;
        payload.selectedProductFamily.products = [];
        for (const product of products) {
          newFamily.addProduct(await ProductFactory.fromJson(product));
        }
        selectedFamily = newFamily;
      }

      /*  let currentWorkshopWork = undefined;

      if (payload.currentWorkshopWork) {
        currentWorkshopWork = await WorkshopFactory.fromJson(
          payload.currentWorkshopWork
        );
      } */
      let userConfigurations = [];

      if (payload.selectedConfigurations) {
        for (const configuration of payload.selectedConfigurations) {
          if (configuration.article_id) {
            userConfigurations.push(
              await ProductFactory.fromJson(configuration)
            );
          } else {
            userConfigurations.push(
              await WorkshopFactory.fromJson(configuration)
            );
          }
        }
      }
      let index = undefined;
      if (payload.selectedIndex && !this.state.selectedIndex) {
        index = payload.selectedIndex;
      }
      let price = 0;
      if (payload.totalPrice) {
        price = payload.totalPrice;
      }
      /*  const workshopMode = payload.currentWorkshopWorkMode;
       */
      const configurationPayload = {
        families: families,
        glasSizeOption: glasSizeOption,
        selectedFamily: selectedFamily
          ? selectedFamily
          : this.state.selectedProductFamily,
        userConfigurations: userConfigurations,
        /* currentWorkshopMode: workshopMode
          ? workshopMode
          : this.state.currentWorkshopMode, */
        //currentWorkshopWork: currentWorkshopWork,
        index: index
          ? index
          : this.state.selectedIndex
            ? this.state.selectedIndex
            : 0,
        price: price,
      };
      commit("setUserConfiguration", configurationPayload);
    },
  },
  getters: {
    productFamilies(state) {
      return state.productFamilies;
    },
    selectedConfigurations(state) {
      return state.selectedConfigurations;
    },
    selectedProductFamily(state) {
      return state.selectedProductFamily;
    },
    selectedIndex(state) {
      return state.selectedIndex;
    },
    totalPrice(state) {
      return state.totalPrice;
    },
    currentProduct(state) {
      return state.selectedProductFamily?.products[
        state.selectedProductFamily.selectedIndex
      ];
    },
    navbarVisibility(state) {
      return state.navBarVisible;
    },
    toastConfiguration(state) {
      return {
        showToast: state.showToast,
        toastType: state.currentToastType,
        message: state.currentToastMessage,
      };
    },
    currentGlasSizeOption(state) {
      return state.currentGlasSizeOption;
    },
    lastCurrentSessionOrder(state) {
      return state.lastCurrentSessionOrder;
    },
    userIsAdmin(state) {
      return state.userIsAdmin;
    },
    isOrderProcessing(state) {
      return state.isOrderProcessing;
    },
    isMobile(state) {
      return state.isMobile;
    },
    isTablet(state) {
      return state.isTablet;
    },
    isDesktop(state) {
      return state.isDesktop;
    },
    currentConfiguratorMode(state) {
      return state.currentConfiguratorMode;
    },
    currentWorkshopMode(state) {
      return state.currentWorkshopMode;
    },
    currentWorkshopWork(state) {
      return state.currentWorkshopWork;
    },
    initialSync(state) {
      return state.initialSync;
    },
    currentDiscountCode(state) {
      return state.currentDiscountCode;
    },
  },
});

createApp(App)
  .use(router)
  .use(store)
  .use(VCalendar)
  .provide("$firebase", firebase)
  .mount("#app");

export default store;
